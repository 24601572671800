import React, { useRef } from 'react';
import { useTranslation } from '@nodeModules/react-i18next';

import { StepModule } from '@root/interfaces/StepModules';
import CountryCode from '@root/interfaces/CountryCode';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import BrCountrySelect, { BrCountrySelectRef } from '@components/common/BrCountrySelect';
import BrCard from '@components/common/BrCard';
import BrFastAccessCountries from '@components/common/BrFastAccessCountries';
import { useIsDesktop } from '@components/common/MediaQueryMatchers';
import { animationComplete } from '@components/common/BrDrawer';
import { onSearchByDialCodeAndAlias } from '@components/common/BrSelect/helpers';

import { getCountryNameByCode } from '@services/countryList';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import { CountryAliases } from '@root/interfaces/appConfig';
import { getSortByKeyFn } from '@utils/array';

import CountrySkeleton from './components/CountrySkeleton';

import Title from '../../components/Title';

interface Props extends StepModule {
  countries: CountryCode[];
  selectedCountryCode?: CountryCode;
  countryAliases?: CountryAliases[];
  featuredCountries?: CountryCode[];
  onFinish(code?: CountryCode): void;
  onFastAccessBtnClick?(): void;
  isFastAccessSectionInDropdownVisible?: boolean;
}

const Country: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {
    selectedCountryCode,
    countries,
    countryAliases,
    featuredCountries,
    onFinish,
    onFastAccessBtnClick,
    isFastAccessSectionInDropdownVisible,
  } = props;

  const { t } = useTranslation();

  const brCountrySelectRef = useRef<BrCountrySelectRef>(null);

  const isDesktopResolution = useIsDesktop();

  const handleCountrySelect = async (value: CountryCode) => {
    if (isDesktopResolution && brCountrySelectRef.current?.dropdownRef) {
      await animationComplete(brCountrySelectRef.current?.dropdownRef);
    } else if (brCountrySelectRef.current?.drawerRef) {
      await animationComplete(brCountrySelectRef.current?.drawerRef);
    }
    if (value === selectedCountryCode) {
      onFinish(undefined);
      return undefined;
    }
    if (value !== selectedCountryCode) {
      onFinish(value); // automatically finish the step on country selection
    }
    return undefined;
  };

  const handleOnSearch = (option: BrOptionProps, searchQuery: string) => {
    return onSearchByDialCodeAndAlias(option, searchQuery, countryAliases);
  };

  const selectData = [
    {
      id: '1',
      label: t('All'),
      options: countries
        .map((code) => {
          return {
            value: code,
            text: t(getCountryNameByCode(code)) ?? '',
            addonTextLeft: `+${getPhoneDialCodeByCountryCode(code)}`,
          };
        })
        .sort(getSortByKeyFn('text')),
    },
  ];

  return (
    <>
      {countries.length ? (
        <>
          <BrCard className="!overflow-visible">
            <div className="space-y-default">
              <div className="space-y-middle">
                <Title text={t('select recipient’s country')} />
                <BrCountrySelect
                  placeholder={t('Enter a country')}
                  drawerTitleText={t("Select recipient's country")}
                  hasSearch
                  data={selectData}
                  value={selectedCountryCode || ''}
                  onChange={handleCountrySelect}
                  onSearch={handleOnSearch}
                  iconLeft={selectedCountryCode ? undefined : 'search'}
                  onWhat="surface"
                  featuredCountries={
                    isFastAccessSectionInDropdownVisible ? featuredCountries : undefined
                  }
                  onFastAccessBtnClick={onFastAccessBtnClick}
                  ref={brCountrySelectRef}
                />
              </div>
              {featuredCountries ? (
                <BrFastAccessCountries
                  data={featuredCountries}
                  onItemClick={handleCountrySelect}
                />
              ) : null}
            </div>
          </BrCard>
        </>
      ) : (
        <CountrySkeleton />
      )}
    </>
  );
};

export default Country;
