import { forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import BrSelectProps, {
  BrOptionProps,
  OptGroupProps,
  BrSelectChildrenType,
} from '@root/interfaces/components/BrSelect';
import CountryCode from '@root/interfaces/CountryCode';

import BrSelect, { BrSelectRef } from '../BrSelect';
import Flag from '../Flag';
import BrCountryList from '../BrCountryList';

interface Props extends Omit<BrSelectProps, 'options' | 'disabled' | 'setForcedOpen'> {
  data: (BrOptionProps | OptGroupProps)[];
  featuredCountries?: CountryCode[];
  children?: BrSelectChildrenType;
  onChange?(value: string): void;
  onClickOutside?(): Promise<void>;
}

interface Props extends Omit<BrSelectProps, 'options'> {
  value: string;
  onSearch?(option: BrOptionProps, searchQuery: string): boolean;
  onFastAccessBtnClick?(): void;
}

export type BrCountrySelectRef = {
  dropdownRef: HTMLDivElement | undefined;
  drawerRef: HTMLDivElement | undefined;
};

const BrCountrySelect = forwardRef<BrCountrySelectRef, Props>((props, ref) => {
  const {
    data,
    value,
    placeholder,
    isOpened,
    isDisabled,
    onChange,
    onSearch,
    drawerTitleText,
    onWhat,
    featuredCountries,
    onFastAccessBtnClick,
    ...rest
  } = props;

  const brSelectRef = useRef<BrSelectRef>(null);

  useImperativeHandle(ref, () => {
    return {
      dropdownRef: brSelectRef.current?.dropdownRef.current ?? undefined,
      drawerRef: brSelectRef.current?.drawerRef.current ?? undefined,
    };
  });

  const selectButtonAddonBefore = value && (
    <Flag
      className="w-[16px] h-[16px] overflow-hidden rounded-full flex-shrink-0 mx-middle self-center"
      code={value as CountryCode}
    />
  );

  const { t } = useTranslation();

  const handleOnChange = (newValue: string) => {
    onChange?.(newValue);
  };

  const renderOverlay: BrSelectProps['renderOverlay'] = (renderProps) => {
    const { options, onItemSelect, searchValue, selectedOption } = renderProps;

    const searchResultsOptGroup = {
      id: 'search-results',
      label: t('Search results'),
      options: options as BrOptionProps[], // TODO
    };

    const fastAccessOptGroup = {
      label: t('Popular') as string,
      options: featuredCountries ?? [],
    };

    const listData = searchValue ? [searchResultsOptGroup] : options;
    const fastAccessData =
      searchValue || !featuredCountries ? undefined : fastAccessOptGroup;

    const handleOnFastAccessBtnClick = async (code: CountryCode) => {
      await onItemSelect?.({ value: code } as BrOptionProps);
      onFastAccessBtnClick?.();
    };

    return (
      <BrCountryList
        data={listData}
        onOptionSelect={onItemSelect}
        selectedOption={selectedOption}
        fastAccessSectionData={fastAccessData}
        onFastAccessBtnClick={handleOnFastAccessBtnClick}
      />
    );
  };

  return (
    <BrSelect
      ref={brSelectRef}
      selectButtonAddonBefore={selectButtonAddonBefore}
      options={data}
      onChange={handleOnChange}
      value={value}
      placeholder={placeholder}
      isOpened={isOpened}
      isDisabled={isDisabled}
      drawerTitleText={drawerTitleText}
      onSearch={onSearch}
      onWhat={onWhat}
      renderOverlay={renderOverlay}
      dropdownPaddingVariant="small"
      {...rest}
    />
  );
});

export default BrCountrySelect;
