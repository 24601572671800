import { useTranslation } from 'react-i18next';

import CountryCode from '@root/interfaces/CountryCode';

import { getCountryNameByCode } from '@services/countryList';

import { normalizeStringCompound } from '@utils/string';

import BrButton from '../BrButton';

interface Props {
  classNames?: string;
  data: CountryCode[];
  onItemClick(code: CountryCode): void;
}

const BrFastAccessCountries: React.FC<Props> = (props) => {
  const { classNames, data, onItemClick } = props;

  const { t } = useTranslation();

  return (
    <div className={normalizeStringCompound([classNames])}>
      <ul className="flex flex-wrap gap-small">
        {data.map((code) => (
          <li key={code}>
            <BrButton
              onClick={() => onItemClick(code)}
              text={t(getCountryNameByCode(code))}
              size="middle"
              cmpType="gray"
              hasIconLeft
              iconCountryCode={code}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BrFastAccessCountries;
