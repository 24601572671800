import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { normalizeStringCompound } from '@utils/string';
import { getSortByKeyFn } from '@utils/array';

import { getCountryNameByCode } from '@services/countryList';

import CountryCode from '@root/interfaces/CountryCode';
import { BrOptionProps } from '@root/interfaces/components/BrSelect';

import { AppConfigStore } from '@root/stores';

import CallingPlan from '@root/models/CallingPlans/CallingPlan';

import useStore from '@root/hooks/useStore';

import BrCountrySelect from '@components/common/BrCountrySelect';
import BrFilter from '@components/common/BrFilter';
import { onSearchByDialCodeAndAlias } from '@components/common/BrSelect/helpers';

import { getPhoneDialCodeByCountryCode } from '@helpers/phone';

import PlansListSkeleton from './components/PlansList/skeleton';
import PlansList from './components/PlansList/index';
import useCallingPlansFilter from './useCallingPlansFilter';

interface Props {
  destinationCountries: CountryCode[];
  availablePlans: CallingPlan[];
  activePlans: CallingPlan[];
  suggestedPlans: CallingPlan[];
  nonPurchasablePlans: CallingPlan[];
  selectedCountryCode?: CountryCode;
  selectedPlanId?: string;
  dataTestPrefix?: string;
  onCountrySelect?(countryCode: CountryCode): void;
  onPlantSelect?(planId: string): void;
}

const getCountryFilterFn = (countryCode?: CountryCode) => {
  return (plan: CallingPlan) => {
    if (countryCode) {
      return plan.destinationCountries.includes(countryCode);
    }
    return plan;
  };
};

const Setup: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    destinationCountries,
    availablePlans,
    suggestedPlans,
    activePlans,
    nonPurchasablePlans,
    selectedCountryCode,
    selectedPlanId,
    onCountrySelect,
    onPlantSelect,
  } = props;

  const { t } = useTranslation();

  const appConfigStore: AppConfigStore = useStore('AppConfigStore');

  const isLoading = !(availablePlans.length || activePlans.length);

  const { filterOptions, filterFnsPipe, handleFilterSelect } = useCallingPlansFilter();

  const filteredAvailablePlans = filterFnsPipe(availablePlans).filter(
    getCountryFilterFn(selectedCountryCode),
  );
  const filteredSuggestedPlans = filterFnsPipe(suggestedPlans).filter(
    getCountryFilterFn(selectedCountryCode),
  );
  const filteredNonPurchasablePlans = filterFnsPipe(nonPurchasablePlans).filter(
    getCountryFilterFn(selectedCountryCode),
  );

  const handleSearch = (option: BrOptionProps, searchQuery: string) => {
    return onSearchByDialCodeAndAlias(
      option,
      searchQuery,
      appConfigStore.countryAliasesCfg,
    );
  };

  return (
    <div className="flex flex-col">
      {isLoading ? (
        <PlansListSkeleton />
      ) : (
        <>
          <div
            className={normalizeStringCompound([
              'flex flex-col space-y-small',
              activePlans.length ? 'mb-default' : 'mb-small',
            ])}
          >
            <div className="text-body/primary/demibold">{t('Calling destination')}</div>
            <BrCountrySelect
              data={destinationCountries
                .map((code) => {
                  const countryName = t(getCountryNameByCode(code));
                  return {
                    value: code,
                    text: countryName || code,
                    addonTextLeft: getPhoneDialCodeByCountryCode(code)
                      ? `+${getPhoneDialCodeByCountryCode(code)}`
                      : '',
                  };
                })
                .sort(getSortByKeyFn('text'))}
              value={selectedCountryCode || ''}
              hasSearch
              placeholder={t('Select or type a country')}
              drawerTitleText={t('Calling destinations')}
              onChange={onCountrySelect}
              dropdownMaxHeight={300}
              onSearch={handleSearch}
            />
          </div>
          <div className="flex flex-col space-y-xsmall pb-xlarge">
            {activePlans.length ? (
              <PlansList
                title={t('Active plans')}
                type="active"
                data={activePlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}

            <div className="overflow-x-auto br-container-x-gutter py-default !-mb-small">
              <BrFilter
                options={filterOptions}
                defaultValue="all"
                shouldFallbackToDefault
                isMultiSelect
                onItemSelect={handleFilterSelect}
              />
            </div>

            {filteredSuggestedPlans.length ? (
              <PlansList
                title={t('Suggested plans')}
                type="suggested"
                data={filteredSuggestedPlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}

            {filteredAvailablePlans.length ? (
              <PlansList
                title={t('Available plans')}
                type="available"
                data={filteredAvailablePlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}

            {filteredNonPurchasablePlans.length ? (
              <PlansList
                title={t('Ineligible plans')}
                type="ineligible"
                data={filteredNonPurchasablePlans}
                selectedPlanId={selectedPlanId}
                onPlanSelect={onPlantSelect}
              />
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default observer(Setup);
